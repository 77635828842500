<template>
<h2>
        Le contrôle qualité
      </h2>
      <!--   /////////////////La granulation /////////////////////////// -->
      <div class="ensemble_production">
        <div class="container">
       
          <div class="content content_image">
            <div class="bordure"></div>
            <div class="image image_cq"></div>
          </div>

          <div class="content content_texte">
            <h3>Analyses : précision et expertise</h3>
            <p>
              Le laboratoire de contrôle assure la qualité des matières premières, articles de conditionnement et produits finis par une équipe de techniciens physico-chimistes, par la qualification de nos équipements et par le respect de l’évolution de la réglementation.
            </p>
            <p>
              <ul>
                <li>Contrôle physique</li>
                <li>Développement de méthodes</li>
                <li>Transfert analytique</li>
                <li>Etudes de stabilités</li>
                <li>Conformité des matières premières</li>
                <li>Analyses à libération des produits finis</li>
              </ul>
            </p>

        
        </div>
        </div>
      </div>
</template>

<script>
export default {
  name: "CQ-component",
};
</script>

<style src="./CQ-component.scss" lang="scss" scoped></style>
