<template>
  <div class="sp">
    <div class="sp_container">
      <h2>Les étapes de fabrication du laboratoire AJC Pharma</h2>
      <!--   /////////////////La granulation /////////////////////////// -->
      <div class="ensemble_production">
        <div class="container">
          <div class="content content_image">
            <div class="bordure"></div>
            <div class="image image_granulation"></div>
          </div>
          <div class="content content_texte">
            <h3>La mise en forme des poudres</h3>
            <p>
              L'étape de mélange, à sec ou granulation humide, est une étape clé
              de la production pharmaceutique.
            </p>
            <p>
              Le respect de la qualité tout en maintenant les bons paramètres de
              fabrication permettent à AJC de produire dans les meilleures
              conditions vos médicaments ou compléments alimentaires.
            </p>
            <ul class="list">
              <li class="item">4 mélangeurs granulateurs</li>
              <li class="item">
                3 mélangeurs pour phase externe 600L et 2000L
              </li>
              <li class="item">4 étuves</li>
            </ul>
          </div>
        </div>
        <!--   /////////////////La compression/////////////////////////// -->

        <div class="container">
          <div class="content content_texte">
            <h3>La compression</h3>
            <p>
              La fabrication des ces comprimés est divisée en 4 étapes : Une
              première étape d'alimentation et de remplissage de la chambre de
              compression.
            </p>
            <p>
              La deuxième étape consiste à générer un arasage du superflu de
              grain de particule.
            </p>
            <p>
              La troisième étape consiste à créer une compression suffisante
              pour fabriquer le comprimé.
            </p>
            <p>
              Enfin la dernière étape est celle de l'éjection de la machine.
            </p>

            <ul class="list">
              <li class="item">2 FETTE P1200</li>
              <li class="item">2 FETTE P1200i</li>
              <li class="item">3 géluleuses IMA</li>
            </ul>
          </div>
          <div class="content content_image">
            <div class="bordure"></div>
            <div class="image image_compression"></div>
          </div>
        </div>
        <!--   /////////////////Le pelliculage/////////////////////////// -->
        <div class="container">
          <div class="content content_image">
            <div class="bordure"></div>
            <div class="image image_pelliculage"></div>
          </div>
          <div class="content content_texte">
            <h3>Le pelliculage</h3>
            <p>
              Le pelliculage est un processus aboutissant à la formation d’une
              fine couche de polymère autour du comprimé.
            </p>
            <p>
              La technologie de pelliculage met en oeuvre à la fois de solides
              connaissances des ingrédients et de leurs interactions, un
              savoir-faire en formulation et une expertise des différentes
              procédés de pelliculage de manière à obtenir un film lisse,
              régulier et homogène recouvrant totalement la surface du comprimé.
            </p>
            <ul class="list">
              <li class="item">3 turbines Accela Cota</li>
            </ul>
          </div>
        </div>
        <!--   /////////////////La dragéification/////////////////////////// -->
        <div class="container">
          <div class="content content_texte">
            <h3>La dragéification</h3>
            <p>
              La dragéification est une méthode d'enrobage des comprimés.
              L'enrobage permet de protéger le principe actif du médicament des
              altérations extérieures telles que les sucs digestifs, le pH acide
              gastrique...
            </p>
            <p>
              Enrober un comprimé peut aussi permettre de masquer une saveur
              désagréable.
            </p>
            <ul class="list">
              <li class="item">6 turbines Frogerais</li>
              <li class="item">4 turbines Pellegrini</li>
              <li class="item">2 étuves</li>
            </ul>
          </div>
          <div class="content content_image">
            <div class="bordure"></div>
            <div class="image image_dragee"></div>
          </div>
        </div>
      </div>
      <!--ensemble_production-->
    </div>
    <!--sp_container-->
  </div>
</template>

<script>
export default {
  name: "fabrication-component",
};
</script>

<style src="./fabrication-component.scss" lang="scss" scoped></style>
